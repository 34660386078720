.team .box {
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid rgb(62 28 131 / 10%);
  padding: 1rem;
  transition: 0.5s;
  margin-top: 20px;
}
.team .box:hover {
  box-shadow: 0 0 20px 0 rgb(112 121 138 / 18%);
  cursor: pointer;
  margin-top: -20px;
}
.team .details {
  text-align: center;
  margin-top: 10px;
}
.team .img {
  width: 300px;
  height: 300px;
  margin: auto;
  position: relative;
  
}
.team img {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  border: 5px solid #edf0f5;
}
.team .img i {
  color: #108be7;
  font-size: 17px;
  position: absolute;
  top: 35px;
  right: -10px;
}
.team .img::after {
  content: "";
  position: absolute;
  top: -15px;
  left: -15px;
  width: 110px;
  height: 110px;
  border: 0px solid #edf0f5;
  border-radius: 50%;
}
.team label {
  display: inline-block;
  margin-top: 10px;
}
.team i {
  color: grey;
  
}
.team ul {
  display: inline-block;
  margin-top: 5px;
}
.team ul li {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  align-items: center;
  background-color: #f4f5f7;
  border-radius: 50%;
  margin: 5px;
}
.team .button {
  margin-top: 20px;
}
.team .button i {
  color: rgb(0, 0, 0);
  font-size: 16px;
}
.team h1 {
  font-size: 45px;
  font-weight: 500;
}
button {

  background: #ffffff !important;

  
}
button:hover {

  background-color: #008321 !important;
  color: #ffffff !important;
}
.team .button :hover {
  color: rgb(255, 255, 255);

}
@media screen and (max-width: 800px) {

  .team .img {
    width: 160px;
    height: 160px;
    margin: auto;
    position: relative;
    
  }
  .team img {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    border: 5px solid #edf0f5;
  }
  .team .img i {
    color: #108be7;
    font-size: 12px;
    position: absolute;
    top: 35px;
    right: -10px;
  }
  .team .img::after {
    content: "";
    position: absolute;
    top: -15px;
    left: -15px;
    width: 110px;
    height: 110px;
    border: 0px solid #edf0f5;
    border-radius: 50%;
  }
  .team label {
    display: inline-block;
    margin-top: 10px;
  }
  .team i {
    color: grey;
   
  }
  .team ul {
    display: inline-block;
    margin-top: 5px;
  }
  .team ul li {
    display: inline-block;
    width: 28px;
    height: 28px;
    line-height: 40px;
    align-items: center;
    background-color: #f4f5f7;
    border-radius: 50%;
    margin: 5px;
  }




}