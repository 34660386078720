
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500&display=swap");
.historytl * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
 
.histo{


    padding-top: 5vh ;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.0) !important;
    margin-bottom: 0px;


}
.histo h1{
    color: rgb(255, 255, 255);
    font-weight: 700;
    margin-left: -45px;
    background-color: rgba(255, 255, 255, 0.0) !important;

}
.historytl{

    background-image: url("../../../../public/images/hbanner.png");
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    padding-top: 10vh;
    width: 100% !important;
    background-color: rgba(255, 255, 255, 0.3);
    align-items: center;
    justify-content: center;

}

.historytl  .container {
  min-height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 0;

}
.timeline {
  width: 80%;
  height: auto;
  max-width: 800px;
  margin: 0 auto;

  position: relative;
}

.timeline ul {
  list-style: none;
 
}
.historytl .timeline ul li {
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: white;
  border-radius: 10px;
  margin-bottom: 20px;
}
.timeline ul li:last-child {
  margin-bottom: 0;
}
.timeline-content h1 {
  font-weight: 500;
  font-size: 25px;
  line-height: 30px;
  margin-bottom: 10px;
}
.timeline-content p {
  font-size: 16px;
  line-height: 30px;
  font-weight: 300;
}
.timeline-content .date {
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 10px;
  letter-spacing: 2px;
}
@media only screen and (min-width: 768px) {



  .timeline:before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 2px;
    height: 100%;
    background-color: gray;
  }
  .timeline ul li {
    width: 50%;
    position: relative;
    margin-bottom: 50px;
  }
  .timeline ul li:nth-child(odd) {
    float: left;
    clear: right;
    transform: translateX(-30px);
    border-radius: 20px 0px 20px 20px;
  }
  .timeline ul li:nth-child(even) {
    float: right;
    clear: left;
    transform: translateX(30px);
    border-radius: 0px 20px 20px 20px;
  }
  .timeline ul li::before {
    content: "";
    position: absolute;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: gray;
    top: 0px;
  }
  .timeline ul li:nth-child(odd)::before {
    transform: translate(50%, -50%);
    right: -30px;
  }
  .timeline ul li:nth-child(even)::before {
    transform: translate(-50%, -50%);
    left: -30px;
  }
  .timeline-content .date {
    position: absolute;
    top: -30px;
  }
  .timeline ul li:hover::before {
    background-color: aqua;
  }
}
