.ftco-section {
  padding: 7em 0; }
  .ftco-section .contactcontainer {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto; }
    @media (min-width: 576px) {
      .ftco-section  .contactcontainer {
        max-width: 540px; } }
    @media (min-width: 768px) {
      .ftco-section .contactcontainer {
        max-width: 720px; } }
    @media (min-width: 992px) {
      .ftco-section .contactcontainer {
        max-width: 960px; } }
    @media (min-width: 1200px) {
      .ftco-section .contactcontainer {
        max-width: 1140px; } }

 .justify-content-center {
          -webkit-box-pack: center !important;
          -ms-flex-pack: center !important;
          justify-content: center !important; }  
          
          .text-center {
            text-align: center !important; }

            .backgroundco{
              background-image: url(../../../public/images/imgconta.png);
              background-repeat: no-repeat;
              background-position: center;

              background-size: cover;
            }
            .mb-5,
.my-5 {
  margin-bottom: 3rem !important; }
  .heading-section {
    font-size: 28px;
    color: #000; }
    .justify-content-center {
      -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
      justify-content: center !important; }
      .dbox {
        width: 100%;
        margin-bottom: 25px; }
        @media (max-width: 767.98px) {
          .dbox {
            margin-bottom: 25px !important;
            padding: 0 20px; } }
        .dbox p {
          margin-bottom: 0; }
          .dbox p span {
            font-weight: 500;
            color: #000; }
          .dbox p a {
            color: #46b5d1; }
        .dbox .icon {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          background: #3ea64f;
          margin: 0 auto;
          margin-bottom: 20px; }
          .dbox .icon span {
            font-size: 20px;
            color: #fff; }
        .dbox .text {
          width: 100%; } 
          .no-gutters {
            
            margin-right: 0;
            margin-left: 0; }
            .no-gutters > .col,
            .no-gutters > [class*="col-"] {
              padding-right: 0;
              padding-left: 0; }

              .contact-wrap {
                background-color: #3ea64f; }


                #form-message-warning, #form-message-success {
                  display: none; }
                
                #form-message-warning {
                  color: red; }
                  .contactForm {
                    width: 100% !important;
                    
                  }


                  .contactForm .label {
                    color: #000;
                    text-transform: uppercase;
                    font-size: 12px;
                    font-weight: 600; 
                  width: 100% !important}
                  
                  .contactForm .form-control {
                    border: none;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                    padding: 0; }
                  
                  #contactForm .error {
                    color: red;
                    font-size: 12px; }
                  
                  #contactForm .form-control {
                    font-size: 16px; }
                    .form-group {
                      margin-bottom: 1.9rem;
                    width: 100% !important; }

 .cowrapper {
  width: 100%; }
  .w-100 {
    width: 100% !important; }
      .p-md-5 {
    padding: 3rem !important; }
    .p-4 {
      padding: 1.5rem !important; }
      .mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
  color: #fff;}
  .col-md-6{
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px; }



    .form-control {
      display: block;
      width: 100%;
      height: calc(1.5em + 0.75rem + 2px);
      padding: 0.375rem 0.75rem;

      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-color: #3ea64f;
      background-clip: padding-box;
      border: 1px solid #3ea64f;
      border-radius: 0.25rem;
      -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
      transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
      -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
      @media (prefers-reduced-motion: reduce) {
        .form-control {
          -webkit-transition: none;
          -o-transition: none;
          transition: none; } }
      .form-control::-ms-expand {
        background-color: transparent;
        border: 0; }
      .form-control:focus {
        color: #495057;
        background-color: #3ea64f;
        border-color: #80bdff;
        outline: 0;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
      .form-control::-webkit-input-placeholder {
        color: #6c757d;
        opacity: 1; }
      .form-control:-ms-input-placeholder {
        color: #6c757d;
        opacity: 1; }
      .form-control::-ms-input-placeholder {
        color: #6c757d;
        opacity: 1; }
      .form-control::placeholder {
        color: #6c757d;
        opacity: 1; }
      .form-control:disabled, .form-control[readonly] {
        background-color: #3ea64f;
        opacity: 1; }



        .btn-primary {
          color: #fff;
          background-color: #000000;
          border-color: #007bff; }
          .btn-primary:hover {
            color: #fff;
            background-color: #0069d9;
            border-color: #0062cc; }
          .btn-primary:focus, .btn-primary.focus {
            -webkit-box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }
          .btn-primary.disabled, .btn-primary:disabled {
            color: #fff;
            background-color: #007bff;
            border-color: #007bff; }
          .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
          .show > .btn-primary.dropdown-toggle {
            color: #fff;
            background-color: #0062cc;
            border-color: #005cbf; }
            .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
            .show > .btn-primary.dropdown-toggle:focus {
              -webkit-box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }
              #message {
                resize: vertical; }
.col-md-7{
  background-color: #3ea64f ;
}