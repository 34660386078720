.about{
  padding: 0px 0px !important;
  
}
/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.aboutcontainer {
    max-width: 100%!important;

  }
  .aboutcontainer .contentbox {
   padding: 0px 0px  0px 100px !important;
  }

  .aboutcontainer .contentbox .abouth1 {
    border-bottom: 3px solid black;
    margin-bottom: 10px;

}

.aboutcontainer .contentbox .abouth1 h1 {
    
    font-weight: 700;
    font-size: 1.9em !important;
    font-family: "Poppins", sans-serif;
    color: #008321!important;
  }
  
  .aboutcontainer .contentbox ul {
    list-style:circle;
    padding: 0 20px 8px 40px;
    
  }
  .aboutcontainer .contentbox p {
    font-size: 0.95em;
  }
  
  
  .aboutcontainer .contentbox ul li {

    list-style: circle;
    list-style-type: circle;
    
    font-size: 0.8em;
     

  }
  
  .aboutcontainer .contcontentboxent ul i {
  
    font-size: 20px;
    left: 0;
    
    color: #ffc451;
  }
  

  .aboutcontainer .right{

    width: 50%!important;
    
  }
  .aboutcontainer .left{
    width: 50%!important;
  }


  @media screen and (max-width: 768px) {

    .aboutcontainer {
      display: inline !important;
  
    }
    .aboutcontainer .right{

      width: 100%!important;
      margin: auto !important;
      
    }
    .aboutcontainer .left{
      align-items: center;
      text-align: center;
      width: 100%!important;
    }
    .aboutcontainer .contentbox ul {
      list-style:circle;
      padding: 0 20px 8px 30px;
      
    }
    .aboutcontainer .contentbox {
      padding: 0px 0px  0px 30px !important;
     }

  }