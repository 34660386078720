

* {
  padding: 0;
  margin: 0;
  
}



body {

  font-family: 'Open Sans', sans-serif;
  background-color: #fff;
  overflow-x: hidden !important;
}
.container {
  
  max-width: 100%;

  font-weight: 400;
  margin: auto;


}
.locationspecial{
  margin: 20px 50px !important;
}
.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.aboutheading{
  text-align: left!important;
  width: 100%;
  margin-bottom: 30px;
}
h1{
  font-family: 'Young Serif', serif;
  
}
p {

  font-size: 17.4px!important;
  padding-right: 10px;
  margin-bottom: 20px;

}Link {
  line-height: 30px !important;
  margin-bottom: 20px;
}
a {
  text-decoration: none;
  color: #000;
  text-transform: capitalize;
}
ul {
  list-style-type: none;
}
button {
  border: 2.5px solid #008321;
  background-color: white;
  padding: 9px 22px ;
  background: #ffffff;
  color: rgb(0, 132, 24);
  cursor: pointer;
  font-weight: bold;

  font-size: 1.4em;
  
}
button:hover {
  background-color: #008321;
  color: #ffffff;
} 
button i {
  margin-right: 5px;
}
.heading {
  text-align: center;
  width: 80%;
  margin: auto;
}
.heading h1 {
  color: #2d3954;
  font-weight: 600;
  text-transform: capitalize;
 
}
.heading p {
  color: #72809d;
}
textarea,
input {
  border: none;
  outline: none;
  background: none;
}
.background {
  padding: 80px 0;
  position: relative;
  background: #f7f9fc;
}

.grid5 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 30px;
}
.grid6 {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 30px;
}
label {
  color: #2d3954;
  font-size: 14px;
}
.mtop {
  margin-top: 50px;
}
h4 {
  font-weight: 500;
  margin-top: 10px;
}
.shadow {
  box-shadow: 0 0 20px 0 rgb(112 121 138 / 18%);
}
.padding {
  padding: 80px 0;
}
.grid3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}
img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.btn2 {
  border-radius: 50px;
  font-size: 20px;
}
.grid4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
}
.btn3 {
  background: #ff6922;
  border-radius: 50px;
}
.btn4 {
  background-color: #000;
  color: #fff;
}
.btn5 {
  border-radius: 50px;
  padding: 20px 40px;
  color: #27ae60;
  font-size: 20px;
  font-weight: 400;
  border: 5px solid #27ae601f;
  background: #fff;
}



.ProjectBack{

  position: relative;


}
.ProjectBack img{
  width: 100%;
  position: relative;
  border-bottom: 4px solid  #27ae60;

}
.ProjectBack::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  background: rgba(11, 27, 49, 0.529);
  z-index: -1;
}
.back {
  height: 60vh;
  position: relative;
  border-bottom: 4px solid  #27ae60;
}
.back img {
  height: 60vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  object-fit: cover;
}
.back::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 60vh;
  background: rgba(11, 27, 49, 0.529);
  z-index: -1;
}
.back .container {
  color: #fff;
  padding: 120px 0;
}
.back h1 {
  font-size: 2.6em;
  font-weight: 500;
}
.mb {
  margin-bottom: 80px;
}
@media screen and (max-width: 800px) {
  .heading {

    text-align: center;
    width: 95%;
    margin: 10% auto;
  }
  button {
    border: 2px solid #008321;
    background-color: white;
    padding: 8px 15px !important;
    background: #ffffff;
    color: rgb(0, 132, 24);
    cursor: pointer;
    font-weight: bold;
    width: fit-content !important;
    font-size: 1.1em;
    
  }



  .grid4,
  .grid3,
  .grid5 ,
  .grid6 {
    grid-template-columns: repeat(2, 1fr);
  }

  .container {
    max-width: 100% !important;
    margin: auto ;
  }

  .back::after,
  .back img,
  .back {
    height: 60vh;
  }
}
