.recent .text {
  padding: 30px;
}

.recent .category span {
  padding: 4px 15px;
  border-radius: 2px;
  font-weight: 600;
  font-size: 13px;
  display: inline-block;
}
.recent .category i {
  font-size: 16px;
  color: #bec7d8;
}
.recent p {
  color: #72809d;
}
.recent h1 {
  font-size: 45px !important;
  font-weight: 500;
}
.recent .button {
  border-top: 1px solid #e4e8f3;
  padding: 8px 24px;
}
