.staffteam  .box {
    background: #ffffff;
    border-radius: 6px;
    border: 1px solid rgb(62 28 131 / 10%);

    transition: 0.5s;
  }
  .staffteam  .box:hover {
    box-shadow: 0 0 20px 0 rgb(112 121 138 / 18%);
    cursor: pointer;
  }
  .staffteam  .details {
    text-align: center;

  }
  .staffteam  .img {

    position: relative;
    align-items: center;
    text-align: center;
    
  }

  .staffteam  .img i {
    color: #108be7;
    font-size: 17px;
    position: absolute;
    top: 35px;
    right: -10px;
  }
  .staffteam  .img::after {
    content: "";
    position: absolute;
    top: -15px;
    left: -15px;
    width: 110px;
    height: 110px;
    border: 0px solid #edf0f5;
    border-radius: 50%;
  }
  .staffteam  label {
    display: inline-block;
    margin-top: 10px;
  }
  .staffteam  i {
    color: rgb(0, 167, 8);
 
  }
  .staffteam  i:hover {
    color: rgb(0, 37, 13);
 
  }
  .staffteam  ul {
    display: inline-block;
    margin-top: 5px;
  }
  .staffteam  ul li {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    align-items: center;
    background-color: #f4f5f7;
    border-radius: 50%;
    margin: 5px 10px;
  }
  .staffteam  ul li:hover{

    border: 0.4px solid #000000;
    
  }
  .staffteam  .button {
    margin-top: 20px;
  }
  .staffteam  .button i {
    color: white;
    font-size: 20px;
  }
  