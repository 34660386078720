
.featured{
  background-image: url("../../../../public/images/bannerbackground.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  width: 100%;
}
.featured .box {
  box-shadow: 0 0 20px 0 rgb(112 121 138 / 18%);
  background-color: #d3ffde;
  border-radius: 6px;
  text-align: center;
  padding: 45px 5px 45px 5px;;
  cursor: pointer;
}
.featured img {
  width: 100px;
  height: 100px;
  margin: auto;
}
.featured h1 {
  color: #ffffff;
  font-size: 3.2em;
  font-weight: 700;
}

.featured p {
  color: #e1e1e1;
  font-size: 1.2em;
  
}

@media screen and (max-width: 768px) {
  .featured img {
    width: 80px;
    height: 80px;
    margin: auto;
  }
  .featured h1 {
    color: #ffffff;
    font-size: 2em;
    font-weight: 700;
  }
  .featured .box {
    box-shadow: 0 0 20px 0 rgb(112 121 138 / 18%);
    background-color: aliceblue;
    border-radius: 6px;
    text-align: center;
    padding: 15px 2px;
    cursor: pointer;
  }
  
}




.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.autoplay-progress {
  position: absolute;
  right: 16px;
  bottom: 16px;
  z-index: 10;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: var(--swiper-theme-color);
}

.autoplay-progress svg {
  --progress: 0;
  position: absolute;
  left: 0;
  top: 0px;
  z-index: 10;
  width: 100%;
  height: 100%;
  stroke-width: 4px;
  stroke: var(--swiper-theme-color);
  fill: none;
  stroke-dashoffset: calc(125.6 * (1 - var(--progress)));
  stroke-dasharray: 125.6;
  transform: rotate(-90deg);
}
