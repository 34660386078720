div.gallery {

    margin: 5%;
    border: 1.5px solid #ddd;
    padding: 3px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.1);
  }
  div.gallery:hover {
   
    border: 1.5px solid #009b1a;
    background-color: #009b1a;

  }

  
  

  
  div.gallery img {

    width: 100%;
    height: auto;
  }
  
  div.desc {
    padding: 15px;
    text-align: center;
  }
  
  * {
    box-sizing: border-box;
  }
  
  .responsive {
   
    padding: 0 6px;
    float: left;
    width: 42.99999%;
    ;
  }

  
  @media only screen and (max-width: 700px) {
    .responsive {
      width: 42.99999%;
      margin: 6px 0;
    }
  }
  
  @media only screen and (max-width: 500px) {
    .responsive {
      width: 100%;
    }
  }
  
  .clearfix:after {
    content: "";
    display: table;
    clear: both;
  }