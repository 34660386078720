.about {
  margin-bottom: 80px;
   
}

.about .heading {
  text-align: left;
  width: 100%;
  margin-bottom: 30px;
}
.about p {
  font-size: 15px;
  padding-right: 5px;
  margin-bottom: 20px;
  line-height: 30px;
}
.about .container img {
  margin-top: 50px;
  border-radius: 8px;
  border: 1.5px solid #ddd;
  padding: 3px;

  max-width: 100%;

}
.piccontainer{
  width: 100%;
  overflow: hidden;
 
 
}
.right{
  width: 45%!important;
}
.left{
  width: 55%!important;
}

.wrapper{
  width: 100%;

}


@media screen and (max-width: 768px) {
  .about .container {
    flex-direction: column;
  }
  .right{
    width: 100%!important;
  }
  .left{
    width: 100%!important;
  }
  .about p {
    padding: 0;
  }
  .about .container img {
    margin-top: 50px;
    border-radius: 8px;
  }
}
