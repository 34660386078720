.Vision-section {
  padding: 80px 0;
}
.light-bg {
  background-color: #faf6fb;
}

.colvalue{
  
    -ms-flex-align: center!important;
    align-items: center!important;
    display: flex!important;
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}


.ui-steps li {
  padding: 15px 0;
}

.ui-steps li:not(:last-child) {
  border-bottom: 1px solid #f8e3f0;
}
.media {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
}

li {
  display: list-item;
  
}
.mr-4 {
  margin-right: 1.5rem!important;
}
.circle-icon {


  margin-bottom: 1.5rem;


  color: #27ae60;
  font-size: 48px;
  text-align: center;
  line-height: 80px;
  font-weight: 300;
  transition: all 0.3s ease;
}
.mr-4 {
  margin-right: 1.5rem!important;
}
.media-body h3{
  color: #27ae60;
  font-size: 2em;



}
.media-body p{
  color: #000000;
  font-size: 1.2em;

}
@media (max-width:992px) {
  .circle-icon {
      width: 70px;
      height: 70px;
      font-size: 28px;
      line-height: 50px;
  }
}
@media screen and (max-width: 800px) {
  .price .content {
    flex-direction: column;
  }

  .price .content .box {
    width: 100%;
    padding: 0px;
    border-radius: 20px;
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 750px) {
  .media-body h3{
    color: #27ae60;
    font-size: 2em;
   
  }
  .media-body p{
    color: #000000;
    font-size: 1em;
  
  }

}
