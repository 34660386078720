.about1 {
    margin-bottom: 30px;
    max-width: 100%;
    border-top: solid  #008622;
    padding: 2rem;
    
  }
  

  .about1 p {
    font-size: 18px;
    padding-right: 5px !important;
    margin-bottom: 20px;
    line-height: 30px;
  }
  .about1 button{
    float: left;
    width: fit-content;
    margin-left: 5px;
  }

  .about1 .container img {
    margin-top: 50px;
    border-radius: 8px;
    border: 1.5px solid #ddd;
    padding: 3px;

   max-width: 100%;
  
  }
  .piccontainer{
    max-width: 100%;

   
   
  }
  .backslide{
    padding: 0 !important;
  }
  .wrapper{
    max-width:  100%;

   
  }
 
  
  @media screen and (max-width: 900px) {
    .about1 .container {
      flex-direction: column;
    }
    .right{
      width: 100%!important;
    }
    .left{
      width: 100%!important;
    }

    .about1 {

      padding: 2.5rem 1rem 2.5rem 1rem;
      
    }

  }
  @media screen and (max-width: 1200px) {
    .about1 {
      margin-bottom: 0px;

      
    }
  }
  

