.hero {
  background-image: url("../../../assets/image/welcome.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  height: 95vh;
  max-width: 100%;


  
}
.herocallcet{
  background-image: url("../../../assets/image/callcentrebg.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  height: 95vh;
}
.heromobile{
  background-image: url("../../../assets/image/mobile.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  height: 95vh;
}
.heroRadio{
  background-image: url("../../../assets/image/radio.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  height: 95vh;
}
.heromessages{
  background-image: url("../../../assets/image/messages.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  height: 95vh;
}

.heromobile button:hover {
  background-color: #008321 !important;
  color: #ffffff;
} 

.heromessages .textmargn, .heroRadio .textmargn, .heroRadio .textmargn, .heromobile .textmargn, .herocallcet .textmargn{
  padding-top : 25vh;

}
.heromessages button, .herocallcet button, .hero button {
  border: 2.5px solid #008321;
  background-color: white;
  padding: 9px 18px ;
 
  color: rgb(0, 132, 24) !important;
  cursor: pointer;
  font-weight: bold;

  font-size: 1em;
  
  
}
.herocallcet button, .hero button:hover {

  background:  #008321;
  color: rgb(255, 255, 255);

  
}
.herocallcet button:hover {

  background:  #00380e;


  
}
.herocallcet p {

  padding-right: 0px!important;


  
}



.hero .container {
  padding-top : 35vh;

}
.hero h1 {
  color: #ffffff;
  font-size: 4.5em;

  

}
  

.hero h2 {
  color: #ffffff;
  font-size: 3.5em;
}

.hero p {
  color: #fff;
  font-size: 1.6em ;
  opacity: 0.8;
}
.divbutton{
align-items: center;
text-align: center;
margin-top: 10%;
}


input::placeholder {
  font-size: 17px;
  color: black;
}
form .box {
  width: 100%;
  padding: 10px;
  border-left: 1px solid rgba(128, 128, 128, 0.2);
}
form .box:nth-child(1) {
  border-left: none;
}
form h4 {
  font-weight: 500;
}


@media screen and (max-width: 1200px) {

  .hero h1 {
    color: #ffffff;
    font-size: 3em;
    
  
  }
}
@media screen and (max-width: 760px) {
  .hero .container {
    padding-top : 35vh !important;
  
  }
  .heroRadio .textmargn, .heromobile .textmargn{
    padding-top : 15vh;
  
  }
 .herocallcet .textmargn{
    padding-top : 30vh;
  
  }
  .heromessages .textmargn{
    padding-top : 20vh;
  
  }
  .hero h1 {
    color: #ffffff;
    font-size: 2.7em;
    
  
  }

  .heromobile{
    background-image: url("../../../assets/image/mobilesmall.jpg");

  }
  .heromessages{
    background-image: url("../../../assets/image/messagesmall.jpg");

  }
  .heroRadio{
    background-image: url("../../../assets/image/radiosmall.jpg");
  }
  .hero {
    background-image: url("../../../assets/image/welcomesmall.jpg");

  
  
    
  }
  
    

.hero h2 {
  color: #ffffff;
  font-size: 2.2em;
}

  .hero .container {
    padding-top: 30%;
  }
  form {
    display: flex;
    flex-wrap: wrap;
  }
  form .box {
    border-left: none;
  }
  form input {
    width: 50%;
  }
}
@media screen and (max-width: 500px) {
.heroRadio  .flex, .heromobile  .flex {

  align-items:start !important;
}
}
@media screen and (max-width: 298px) {
.herocallcet .textmargn{
  padding-top : 10vh;

}


}