
.service{
    background-color: white !important;

    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;

  }
  .service .box {


    border-radius: 6px;
    text-align: center;
    padding: 10px;
    margin :10% !important;
    cursor: pointer;
  }
  .service img {
    width: 120px;
    height: 120px;
    margin: 30px auto;
  }

  .service h1 {
    color: #000000;
    font-size: 3.2em;

  }
  
  .service p {
    color: #e1e1e1;
    
  }
  
  