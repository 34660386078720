.history {
    background-image: url("../../../public/images/history.png");
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    background-color: rgba(255, 255, 255, 0.6);
    color: #fff;
    text-align: center;
  }
  .history .heading {
    display: flex;
    flex-direction: column-reverse;
  }
  .history .heading p {
    color: #27ae60;
    text-align: center;
  }
  .history .heading h1 {
    text-align: center;
    color: #fff !important;
    font-size: 3.2em ;
    font-weight: 700;
   
  }
  .historycontent{
    display: flex;
    width: 100%;
  } 
  .htext{
    background-color: rgba(255, 255, 255, 0.3) !important;
    margin: 3%;
    padding: 20px;
  }
  .htext p{
    color: rgb(255, 255, 255);
    text-align: left;
    font-size: 1.2em;
  }

  .htext h3{
    color: rgb(232, 232, 232);
    margin: 10px;
    font-size: 2.3em;
  }
  .history .right{

    float: right;
    width: 50%!important;
    margin-left: auto;
  }
  @media screen and (max-width: 800px) {

    .history .right{

      float: right;
      width: 90%!important;
      margin-left: auto;
    }
  }
  
