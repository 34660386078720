.location .box {
  position: relative;
  border-radius: 5px;
}
.location img {
  border-radius: 5px;
}
.location .overlay {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 250px;
  width: 100%;
  color: #fff;
  z-index: 222;
}
.location .overlay::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 340px;
  height: 210px;
  background: rgb(15 28 47 / 30%);
  z-index: -1;
  margin: 20px;
  border-radius: 5px;
}
.location h1 {
  font-size: 45px;
  font-weight: 500;
}
.location label {
  color: #fff;
  margin-right: 20px;
  opacity: 0.8;
}
.contact-block .contact-info {
  padding: 70px 40px 0;
  position: relative;
  z-index: 1;
  font-size: 18px;
  border-radius: 5px;
}
.contact-block .contact-info ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.contact-block .contact-info {
  padding: 70px 40px 0;
  position: relative;
  z-index: 1;
  font-size: 18px;
  border-radius: 5px;
}
.contact-block .contact-info ul {
  text-align: center;
}
.contact-block .contact-info li {
  display: inline-block;
  vertical-align: top;
  width: 32%;
}

.contact-block .contact-info .fas {
  display: block;
  font-size: 40px;
  margin: 0 0 15px;
  color: #006302;
}

.location .swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.location .swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}

.location .swiper-slide img {
  display: block;
  width: 100%;
}

@media screen and (max-width: 800px) {
  .location .overlay::after {
    width: 90%;
    height: 90%;
  }

  .grid3{
    grid-template-columns: repeat(1, 1fr) !important;
  }
  .contact-block .contact-info {
    padding: 30px 0 0;
    font-size: 15px;
  }

  .contact-block .contact-info li {
    display: block;
    width: 100%;
    padding: 0 0 15px;
  }

  .contact-block .contact-info .fas {
    font-size: 26px;
    margin: 0;
  }
}
@media screen and (max-width: 500px) {
  .location .swiper-slide {

  width: 180px;
  height: 180px;
}
}