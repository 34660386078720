.recent{
  background-color: #ededed;
}
.recent .text {
  padding: 15px;
}
.shadow{
  border-radius: 10px;
  background-color: #e2e2e2;
} 
.recent .category span {
  padding: 4px 15px;
  border-radius: 2px;
  font-weight: 600;
  font-size: 13px;
  display: inline-block;
}
.recent .category i {
  font-size: 20px;
  color: #a4aab5;
}
.recent p {
  color: #686c76;
  text-align: center;
  font-size: 0.92em !important;
  line-height: 1.5em;
}
.recent .img {
  border-bottom: 6px solid #00b02c;

}
.recent .img img{
  border-radius: 10px 10px 0px  0px;
}

.recent h4 {
  font-size: 22px !important;
  font-weight: 500 !important;
  text-align: center !important;
  margin-bottom: 15px;
  line-height: 1.5em;
}
.recent .button {
  border-top: 1px solid #e4e8f3;
  padding: 10px 30px;
}
