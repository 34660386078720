/*--------------------------------------------------------------
# Our Team
--------------------------------------------------------------*/
.exteam {
    text-align: center;
}

.exteam .chief{
    text-align: center;
    align-items: center;
    width: 35%;
    margin: auto;

}

.exteam .member {

    overflow: hidden;
    text-align: center;
    border-radius: 5px;
    background: #fff;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  }
  
  .exteam .member .member-img {
    position: relative;
    overflow: hidden;
  }
  
  .exteam .member .social {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 40px;
    opacity: 0;
    transition: ease-in-out 0.3s;
    text-align: center;
    background: rgba(255, 255, 255, 0.85);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .exteam .member .social a {
    transition: color 0.3s;
    color: #111;
    margin: 0 10px;
    display: inline-flex;
    align-items: center;
    line-height: 0;
  }
  
  .exteam .member .social a i {
    line-height: 0;
  }
  
  .exteam .member .social a:hover {
    color: #1bbd36;
  }
  
  .exteam .member .social i {
    font-size: 18px;
    margin: 0 2px;
  }
  
  .exteam .member .member-info {
    padding: 25px 15px;
  }
  
  .exteam .member .member-info h4 {
    font-weight: 700;
    margin-bottom: 5px;
    font-size: 1.3em;
    color: #111;
  }
  
  .exteam .member .member-info span {
    display: block;
    font-size: 1.2em;
    font-weight: 400;
    color: #b4aca8;
  }
  
  .exteam .member .member-info p {
    font-style: italic;
    font-size: 1.0em;
    line-height: 26px;
    color: #847872;
  }
  
  .exteam .member:hover .social {
    opacity: 1;
  }

  .exteam .right, .exteam .left {
    width: 30% !important;
    margin:0 10% !important;
  }
.section-title{
    margin: 30px;
}
.section-title h1{
    font-size: 3.0em;
}

@media screen and (max-width: 800px) {
  .exteam .chief{

    width: 95%;
  

}
.exteam .right, .exteam .left {
  width: 77% !important;
  margin:0 10% !important;
}


}