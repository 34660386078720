/*------------Testimonial---------*/
.customer {
    text-align: center;
  }
  
  .flex1 {
    display: flex;
    justify-content: space-between;
  }
  
  .customer .item {
    text-align: left;
  }
  
  .customer h1 {
    font-size: 3em;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  
  .customer .content {
    padding: 50px 80px;
    margin: 20px;
  }
  
  .customer .item {
    margin: 20px;
    padding: 50px;
    background: white;
    box-shadow: 0 0 5px 0 rgb(0 0 0 / 20%);
  }
  
  .customer .img {
    width: 300px;
    height: 300px;
    z-index: 2;
    position: relative;
  }
  
  .customer img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 5px solid white;
    margin-top: 40px;
  }
  
  .customer .heading {
    margin-bottom: 30px;
  }
  
  .customer .text {
    margin-left: 30px;
  }
  
  .customer h2 {
    color: #2dbd39;
    font-size: xx-large;
  }
  
  .customer h5 {
    font-weight: 400;
  }
  
  .customer i {
    font-size: 50px;
    color: #2dbd39;
  }
  
  .customer p {
    line-height: 25px;
  }
  
  .customer .item {
    position: relative;
  }
  
  .customer .item::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 115px;
    height: 440px;
    background: #2dbd39;
  }
  
  .owl-nav .owl-prev,
  .owl-nav .owl-next {
    height: 40px;
    position: absolute;
    width: 40px;
    cursor: pointer;
    background: #FF6F3D !important;
    top: 43%;
  }
  
  .owl-nav .owl-prev i,
  .owl-nav .owl-next i {
    color: white;
    font-size: 20px;
  }
  
  .owl-nav .owl-prev {
    left: -0;
  }
  
  .owl-nav .owl-next {
    right: -0;
  }
  
  /*------------Testimonial---------*/

  @media only screen and (max-width:768px) {

  
    .trusteecont {
      max-width: 96%;
      margin: auto;
    }
  
 

    /*------------Testimonial---------*/
    .customer {
      text-align: center;
    }
  
    .customer .content {
      padding: 0px;
      margin: 0px;
    }
  
    .customer .item {
      margin: 0px;
      padding: 20px;
    }
  
    .customer .item::after {
      display: none
    }
  
    /*------------Testimonial---------*/
}  