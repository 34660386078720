.footerContact {
  background-color: #258b36;
  padding: 10px 0;
  color: #fff;
  text-align: center;
}
.footerContact h1 {
  font-size: 40px;
}
footer {
  background-color: #1d2636;
  padding: 50px 0;
  color: #fff;
}
footer .container {
  display: grid;
  grid-template-columns: 6fr 2fr 2fr 1fr;
  grid-gap: 20px;
}
footer img {
  width: 150px;
}
footer h2 {
  font-weight: 500;
}
footer p {
  color: grey;
  margin: 20px 0;
}
.footicons ul{
  display: inline-block;
  text-align: center;
  

}
.footicons ul li {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  align-items: center;
  
  border-radius: 50%;
  margin: 5px;
}

.footicons i {
  color: rgb(255, 255, 255) ;
  font-size: 1.4em;
}

.footicons i:hover {
  color:rgb(0, 191, 220) ;
  font-size: 1.7em;
}

footer h3 {
  font-weight: 500;
  margin-bottom: 30px;
}
footer h1 {
  font-weight: 500;
  margin-bottom: 30px;
  font-style: normal;
  
  color: #258b36;
}
footer p{
  font-size: 1.5em;

}
footer ul {
  display: block;
}
footer ul li {
  display: block;
  color: grey;
  margin-bottom: 20px;
}
.legal {
  text-align: center;
  padding: 20px;
  background: #1d2636;
  color: rgba(255, 255, 255, 0.5);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
@media screen and (max-width: 800px) {
  footer .container {
    grid-template-columns: repeat(2, 1fr);
  }
}
