header {
  background-color: rgba(255, 255, 255, 0.99);

  height: 10vh;
  box-shadow: 0 5px 30px rgb(0 22 84 / 10%);
  padding: 15px 0;
  position: fixed;
  border-bottom: solid  #1bbd36;
 
  width: 100%;
  max-width: 100%;
  z-index: 77733 !important;
}
.top {
  background-color: rgb(113,206,126) ;

  height: 5vh;

  padding: 1px 10px;
  position: fixed;

  top:0;
  width: 100%;
  max-width: 100%;
  z-index: 77!important;
}



.social img{
  width: 33px !important;
  float: left;
  padding: 3px;
}
.social img:hover{

  padding: 0px;
  box-shadow: #000000 ;
}


header .social{
  padding: 2px 3px ;
}

header img {
  width: 170px;
  background-color: rgba(255, 255, 255, 0.0)
}


/**
* Desktop Navigation 
*/
.nav {
  padding: 0;
  z-index: 77733 !important;
  text-align: left;
  float: left;
  margin: 0px auto;
}

.nav ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: reft;
  text-align: left;
}

.nav li {
  position: relative;
}

.nav a ,
.nav a :focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-weight: 600;
  color: #000000;
  white-space: nowrap;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.3s;
}



.nav a :hover,
.nav .active,
.nav .active:focus,
.nav li:hover>a  {
  color: #1bbd36 !important;
}

.nav .dropdown ul {
  display: block;
  position: absolute;
  left: 14px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-top: 2px solid #1bbd36;
}

.nav .dropdown ul li {
  min-width: 200px;
}

.nav .dropdown ul a  {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  text-transform: none;
  color: #111!important;
}



.nav .dropdown ul a :hover,
.nav .dropdown ul .active:hover,
.nav .dropdown ul li:hover>a  {
  color: #1bbd36 !important;
}

.nav .dropdown:hover>ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.nav .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.nav .dropdown .dropdown:hover>ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}





header .toggle {
  display: none;
}



.nav form button{
  padding: 10px 10px !important;
}








@media (max-width: 1366px) {
  .nav .dropdown .dropdown ul {
    left: -90%;
  }

  .nav .dropdown .dropdown:hover>ul {
    left: -100%;
  }
}



/**
* Mobile Navigation 
*/


@media screen and (max-width: 1200px) {
  header img {
    width: 120px;
    background-color: rgba(255, 255, 255, 0.0)
  }
  
  
}


@media screen and (max-width: 768px) {
  header {
    padding: 0;
  }
  header img {
    margin-top: 30px;
  }
  header .button {
    display: none;
  }
  header .nav .flex {
    display: none;
  }
  .flex .btn1 {
    display: none;
  }
  header .toggle {
    display: block;
    margin-top: 20px;
  }
  header .small {
    position: absolute;
    top: 10vh;
    left: 0;
    width: 100%;
    background-color: #27ae60;
    padding: 20px;
  }
  header .small li {

    width: 100% !important;

    padding: 20px;
  }
  header li a {
    display: inline-block;
    margin-bottom: 20px;
    color: #fff;
  } 
  .nav ul {
    margin: 0;
    padding: 0;
    display: block;
    list-style: none;
    align-items: center;
  }
}
